<template>
	<apply-data></apply-data>
</template>

<script>
import ApplyData from "@/components/apply/applyData";
export default {
	name: "apply_data",
	components: {ApplyData}
}
</script>

<style scoped>

</style>