<template>
	<div id="apply-data" class="container">
		<div class="content">
			<div class="content_head">
				<div class="inner">
					<div class="breadcrumb">
						<ul>
							<li><a href="#" class="ico_home" title="Home"></a></li>
							<li>서비스 신청</li>
						</ul>
					</div>
					<h2 class="page_title">서비스 신청</h2>
				</div>
			</div>
			<div class="content_body">
				<div class="inner">
					<div class="apply_visual">
						<div class="text_wrap">
							<strong class="tit">잠들어있는 <b>데이터를 분석</b>하여<br> <b>인사이트</b>를 얻으세요.</strong>
							<p class="desc">궁금한 사항은 문의하기를 이용해 주세요</p>
						</div>
						<a href="#" class="btn_apply">문의하기</a>
					</div>
					<!-- 페이지 단계 -->
					<nav class="page_step">
						<ul class="tab_list">
							<!-- 해당 페이지의 탭에 active 클래스 추가 -->
							<li class="tab_item"><span class="num">1</span>기본 정보</li>
							<li class="tab_item active"><span class="num">2</span>데이터 및 결제 정보</li>
							<li class="tab_item"><span class="num">3</span>입력 정보 확인</li>
						</ul>
					</nav>
					<!-- 카페24 테이블 -->
					<div class="tbl_input" v-if="serviceApplyVO.reqChannel==='cafe'">
						<table>
							<tr>
								<th>수집 요청 기간 <span class="require">*</span></th>
								<td>
									<div class="inp_box i1">
										<input type="text" class="inp" v-model="serviceApplyVO.reqDate" placeholder="수집 요청 기간을 입력해 주세요. (예: 전부, 과거 1년, 최근 한 달)">
									</div>
								</td>
							</tr>
							<tr>
								<th rowspan="4">
									카페24 API KEY 정보<br>
									<button type="button" class="btn_th btn_modal" data-id="cafe24">정보 확인 가이드</button><br>
									<p style="font-size: 13px; color: gray; margin-top: 5px;">* 모르시면 안적으셔도 됩니다.</p>
								</th>
								<td>
									<div class="inp_box i1">
										<input type="text" class="inp" v-model="serviceApplyVO.clientId" placeholder="CLIENT ID를 입력해 주세요.">
									</div>
								</td>
							</tr>
							<tr>
								<td>
									<div class="inp_box i1">
										<input type="text" class="inp" v-model="serviceApplyVO.secretKey" placeholder="SECRET KEY를 입력해 주세요.">
									</div>
								</td>
							</tr>
							<tr>
								<td>
									<div class="inp_box i1">
										<input type="text" class="inp" v-model="serviceApplyVO.mailId" placeholder="MAIL ID를 입력해 주세요.">
									</div>
								</td>
							</tr>
							<tr>
								<td>
									<div class="inp_box i1">
										<input type="text" class="inp" v-model="serviceApplyVO.redirectUrl" placeholder="REDIRECT URL을 입력해 주세요.">
									</div>
								</td>
							</tr>
							<tr>
								<th>요청사항</th>
								<td>
									<div class="inp_box">
										<textarea name="" class="tta" cols="30" rows="5" v-model="serviceApplyVO.reqMsg" placeholder="요청사항 작성시 바로 연락받으실 수 있는 연락처를 반드시 기재부탁드립니다.(5000자)"></textarea>
									</div>
								</td>
							</tr>
						</table>
					</div>
					<!-- 고도몰 테이블 -->
					<div class="tbl_input" v-if="serviceApplyVO.reqChannel==='godo'">
						<table>
							<tr>
								<th>수집 요청 기간 <span class="require">*</span></th>
								<td>
									<div class="inp_box i1">
										<input type="text" class="inp" v-model="serviceApplyVO.reqDate" placeholder="수집 요청 기간을 입력해 주세요. (예: 전부, 과거 1년, 최근 한 달)">
									</div>
								</td>
							</tr>
							<tr>
								<th rowspan="2">
									고도몰 API KEY 정보<br>
									<button type="button" class="btn_th btn_modal" data-id="godomall">정보 확인 가이드</button>
								</th>
								<td>
									<div class="inp_box i1">
										<input type="text" class="inp" v-model="serviceApplyVO.partnerId" placeholder="PARTNER ID를 입력해 주세요.">
									</div>
								</td>
							</tr>
							<tr>
								<td>
									<div class="inp_box i1">
										<input type="text" class="inp" v-model="serviceApplyVO.secretKey" placeholder="쇼핑몰 인증키를 입력해 주세요.">
									</div>
								</td>
							</tr>
							<tr>
								<th>요청사항</th>
								<td>
									<div class="inp_box">
										<textarea name="" class="tta" cols="30" rows="5" v-model="serviceApplyVO.reqMsg" placeholder="문의 내용 및 필요하신 사항 입력과 바로 받으실 수 있는 전화번호를 기재하시면 빠르게 연락을 받으실 수 있습니다.(5000자)"></textarea>
									</div>
								</td>
							</tr>
						</table>
					</div>
					<!-- ERP 테이블 -->
					<div class="tbl_input" v-if="serviceApplyVO.reqChannel==='erp'">
						<table>
							<tr>
								<th>수집 요청 기간 <span class="require">*</span></th>
								<td>
									<div class="inp_box i1">
										<input type="text" class="inp" v-model="serviceApplyVO.reqDate" placeholder="수집 요청 기간을 입력해 주세요. (예: 전부, 과거 1년, 최근 한 달)">
									</div>
								</td>
							</tr>
							<tr>
								<th>ERP</th>
								<td>
									<div class="inp_box i1">
										<input type="text" class="inp" v-model="serviceApplyVO.erpName" placeholder="사용하시는 ERP 제품명을 입력해 주세요. (예: XDM, 더존 ERP 등)">
									</div>
								</td>
							</tr>
							<tr>
								<th>요청사항</th>
								<td>
									<div class="inp_box">
										<textarea name="" class="tta" cols="30" rows="5" v-model="serviceApplyVO.reqMsg" placeholder="문의 내용 및 필요하신 사항 입력과 바로 받으실 수 있는 전화번호를 기재하시면 빠르게 연락을 받으실 수 있습니다.(5000자)"></textarea>
									</div>
								</td>
							</tr>
						</table>
					</div>
					<!-- 고객행동데이터 테이블 -->
					<div class="tbl_input" v-if="serviceApplyVO.reqChannel==='data'">
						<table>
							<tr>
								<th>수집 요청 기간 <span class="require">*</span></th>
								<td>
									<div class="inp_box i1">
										<input type="text" class="inp" v-model="serviceApplyVO.reqDate" placeholder="수집 요청 기간을 입력해 주세요. (예: 전부, 과거 1년, 최근 한 달)">
									</div>
								</td>
							</tr>
							<tr>
								<th>고객행동데이터<br>(수집 희망 웹사이트 주소)</th>
								<td>
									<div class="inp_box i1">
										<input type="text" class="inp" v-model="serviceApplyVO.webUrl" placeholder="대상 웹사이트 주소(URL) 정보를 입력해 주세요.">
									</div>
								</td>
							</tr>
							<tr>
								<th>요청사항</th>
								<td>
									<div class="inp_box">
										<textarea name="" class="tta" cols="30" rows="5" v-model="serviceApplyVO.reqMsg" placeholder="문의 내용 및 필요하신 사항 입력과 바로 받으실 수 있는 전화번호를 기재하시면 빠르게 연락을 받으실 수 있습니다.(5000자)"></textarea>
									</div>
								</td>
							</tr>
						</table>
					</div>
					<!-- 기타 테이블 -->
					<div class="tbl_input" v-if="serviceApplyVO.reqChannel==='etc' || serviceApplyVO.reqChannel==='datapresso' || serviceApplyVO.reqChannel==='shop' || serviceApplyVO.reqChannel==='ensol' || serviceApplyVO.reqChannel==='anal'">
						<table>
							<tr>
								<th>수집 요청 기간 <span class="require">*</span></th>
								<td>
									<div class="inp_box i1">
										<input type="text" class="inp" v-model="serviceApplyVO.reqDate" placeholder="수집 요청 기간을 입력해 주세요. (예: 전부, 과거 1년, 최근 한 달)">
									</div>
								</td>
							</tr>
							<tr>
								<th>요청사항</th>
								<td>
									<div class="inp_box">
										<textarea name="" class="tta" cols="30" rows="5" v-model="serviceApplyVO.reqMsg" placeholder="문의 내용 및 필요하신 사항 입력과 바로 받으실 수 있는 전화번호를 기재하시면 빠르게 연락을 받으실 수 있습니다.(5000자)"></textarea>
									</div>
								</td>
							</tr>
						</table>
					</div>
					<!-- 컨설팅 테이블 -->
					<div class="tbl_input" v-if="serviceApplyVO.reqChannel==='consul'">
						<table>
							<tr>
								<th>요청사항</th>
								<td>
									<div class="inp_box">
										<textarea name="" class="tta" cols="30" rows="5" v-model="serviceApplyVO.reqMsg" placeholder="문의 내용 및 필요하신 사항 입력과 바로 받으실 수 있는 전화번호를 기재하시면 빠르게 연락을 받으실 수 있습니다.(5000자)"></textarea>
									</div>
								</td>
							</tr>
						</table>
					</div>
					<!-- 납부방법 테이블 -->
					<div class="tbl_input" v-if="!haveBillInfo && serviceApplyVO.reqChannel!=='datapresso'">
						<table>
							<tr>
								<th>납부방법 선택 <span class="require">*</span></th>
								<td>
									<div class="inp_box i1">
										<input type="text" class="inp" v-model="userInfo.payWay" placeholder="신용카드 자동납부" disabled>
									</div>
									<p class="tbl_info">
										<b>신용카드 납부</b>만 가능하며, 추가 결제 수단이 등록되면 공지사항으로 안내드리겠습니다.
									</p>
								</td>
							</tr>
							<tr>
								<th>예금주 <span class="require">*</span></th>
								<td>
									<div class="inp_box i1">
										<input type="text" class="inp" v-model="userInfo.companyName" placeholder="이름(회사명)을 입력해 주세요.">
									</div>
								</td>
							</tr>
							<tr>
								<th>전화번호 <span class="require">*</span></th>
								<td>
									<div class="inp_box i1">
										<input type="text" class="inp" v-model="userInfo.phoneNumber" placeholder="전화번호를 입력해 주세요. (입력 시 -은 제외해 주세요.)">
									</div>
								</td>
							</tr>
							<!-- 취소 테스트용
							<tr>
								<th>TID <span class="require">*</span></th>
								<td>
									<div class="inp_box i1">
										<input type="text" class="inp" v-model="tid" placeholder="취소용 TID">
									</div>
								</td>
							</tr>
							-->
						</table>
					</div>
					<div class="button_area">
						<button type="button" class="btn_cancle btn_black" @click="goBack">취소</button>
                        <button type="submit" class="btn_submit btn_primary" v-if="!haveBillInfo && serviceApplyVO.reqChannel !== 'datapresso'" @click="paybtn">신용카드 인증</button>
                        <button type="submit" class="btn_submit btn_primary" v-if="haveBillInfo || serviceApplyVO.reqChannel === 'datapresso'" @click="nextPage">다음</button>
						<!-- 취소 테스트용
						<button type="submit" class="btn_submit" @click="testCancel">결제취소</button>
						-->
					</div>
				</div>

				<form name="" id="SendPayForm_id" method="post" class="mt-5">
					<div class="row g-3 justify-content-between" style="--bs-gutter-x:0rem; display: none;">

						<!--label class="col-10 col-sm-2 gap-2 input param" style="border:none;">version</label>
						<label class="col-10 col-sm-9 input"-->
						<input type="hidden" name="version" value="1.0">
						<!--/label-->

						<label class="col-10 col-sm-2 input param" style="border:none;">gopaymethod</label>
						<label class="col-10 col-sm-9 input">
							<input type="text" name="gopaymethod" :value="pgConfig.gopaymethod">
						</label>

						<label class="col-10 col-sm-2 input param" style="border:none;">mid</label>
						<label class="col-10 col-sm-9 input">
							<input type="text" name="mid" :value="pgConfig.mid">
						</label>

						<label class="col-10 col-sm-2 input param" style="border:none;">oid</label>
						<label class="col-10 col-sm-9 input">
							<input type="text" name="oid" :value="serviceApplyVO.orderNumber">
						</label>

						<label class="col-10 col-sm-2 input param" style="border:none;">price</label>
						<label class="col-10 col-sm-9 input">
							<input type="text" name="price" :value="pgConfig.price">
						</label>

						<label class="col-10 col-sm-2 input param" style="border:none;">timestamp</label>
						<label class="col-10 col-sm-9 input">
							<input type="text" name="timestamp" :value="pgConfig.timestamp">
						</label>


						<input type="hidden" name="signature" :value="pgConfig.signature">
						<input type="hidden" name="mKey" :value="pgConfig.mKey">
						<input type="hidden" name="currency" :value="pgConfig.currency">


						<label class="col-10 col-sm-2 input param" style="border:none;">goodname</label>
						<label class="col-10 col-sm-9 input">
							<input type="text" name="goodname" value="신용카드 인증">
						</label>

						<label class="col-10 col-sm-2 input param" style="border:none;">buyername</label>
						<label class="col-10 col-sm-9 input">
							<input type="text" name="buyername" :value="userInfo.companyName">
						</label>

						<label class="col-10 col-sm-2 input param" style="border:none;">buyertel</label>
						<label class="col-10 col-sm-9 input">
							<input type="text" name="buyertel" :value="userInfo.phoneNumber">
						</label>

						<label class="col-10 col-sm-2 input param" style="border:none;">buyeremail</label>
						<label class="col-10 col-sm-9 input">
							<input type="text" name="buyeremail" :value="serviceApplyVO.emailId">
						</label>

						<input type="hidden" name="returnUrl" :value="pgConfig.siteDomain + pgConfig.returnUrl">
						<input type="hidden" name="closeUrl" :value="pgConfig.siteDomain + pgConfig.closeUrl">
<!--						<input type="hidden" name="returnUrl" value="https://[가맹점도메인]/INIbill_pc_return.jsp">-->
<!--						<input type="hidden" name="closeUrl" value="https://[가맹점도메인]/close.jsp">-->

						<label class="col-10 col-sm-2 input param" style="border:none;">acceptmethod</label>
						<label class="col-10 col-sm-9 input">
							<input type="text" name="acceptmethod" value="HPP(1):below1000:va_receipt:BILLAUTH(Card)">
						</label>

					</div>
				</form>

			</div>

			<!-- Guide Modal -->
			<div class="modal">
				<div class="modal_content modal_guide" data-id="cafe24">
					<div class="modal_head">
						<button type="button" class="btn_close"><span class="blind">닫기</span></button>
					</div>
					<div class="modal_body">
						<div class="tabs">
							<ul class="tab_menu">
								<li class="tab_item active">1. CAFE24 APP 구성</li>
								<li class="tab_item">2. 개인정보 서약서 작성</li>
								<li class="tab_item">3. 인증정보 입력</li>
							</ul>
							<div class="tab_content">
								<div class="tab_panel">
									<h3 class="guide_title blind">1. CAFE24 APP 구성</h3>
									<div class="guide_group">
										<h4 class="group_title">1. CAFE24 개발자 센터 구성</h4>
										<ul class="guide_list">
											<li>
												<span class="num">1</span>
												<img src="/wapu/assets/img/content/img_guide_01_01.jpg" alt="">
												<p>접속 경로 : <a href="https://developers.cafe24.com/ko/developer/front/login" target="_blank">https://developers.cafe24.com/ko/developer/front/login</a></p>
											</li>
											<li>
												<span class="num">2</span>
												<img src="/wapu/assets/img/content/img_guide_01_02.jpg" alt="">
											</li>
											<li>
												<span class="num">3</span>
												<img src="/wapu/assets/img/content/img_guide_01_03.jpg" alt="">
											</li>
											<li>
												<span class="num">4</span>
												<img src="/wapu/assets/img/content/img_guide_01_04.jpg" alt="">
											</li>
										</ul>
									</div>
									<div class="guide_group">
										<h4 class="group_title">2. CAFE24 APP 생성</h4>
										<ul class="guide_list">
											<li>
												<span class="num">1</span>
												<img src="/wapu/assets/img/content/img_guide_01_05.jpg" alt="">
											</li>
											<li>
												<span class="num">2</span>
												<img src="/wapu/assets/img/content/img_guide_01_06.jpg" alt="">
											</li>
											<li>
												<span class="num">3</span>
												<img src="/wapu/assets/img/content/img_guide_01_07.jpg" alt="">
											</li>
										</ul>
									</div>
									<div class="guide_group">
										<h4 class="group_title">3. CAFE24 APP 기본 환경 구성</h4>
										<ul class="guide_list">
											<li>
												<span class="num">1</span>
												<img src="/wapu/assets/img/content/img_guide_01_08.jpg" alt="">
												<p>
													- App URL : https://카페24관리자ID.cafe24.com<br>
													- Redirect URL : https://카페24관리자ID.cafe24.com
												</p>
											</li>
										</ul>
									</div>
									<div class="guide_group">
										<h4 class="group_title">4. CAFE24 API 권한 구성</h4>
										<ul class="guide_list">
											<li>
												<span class="num">1</span>
												<img src="/wapu/assets/img/content/img_guide_01_09.jpg" alt="">
												<p>각 권한 항목별로 상점분류, 상품분류, 상품, 판매분류, 개인화정보, 주문, 회원, 상점 선택 후 추가 버튼 클릭 (총 8개 권한)</p>
											</li>
										</ul>
									</div>
									<div class="guide_group">
										<h4 class="group_title">5. API 인증 정보 추출</h4>
										<ul class="guide_list">
											<li>
												<span class="num">1</span>
												<img src="/wapu/assets/img/content/img_guide_01_10.jpg" alt="">
												<p>위의 보기 모두 클릭 후 Client ID, Client Secret Key 값 추출</p>
											</li>
										</ul>
									</div>
								</div>
								<div class="tab_panel">
									<h3 class="guide_title blind">2. 개인정보 서약서 작성</h3>
									<div class="guide_group">
										<h4 class="group_title">1-1. 개인정보 취급 서약서 작성(서약서 다운로드)</h4>
										<ul class="guide_list">
											<li>
												<span class="num">1</span>
												<img src="/wapu/assets/img/content/img_guide_02_01.jpg" alt="">
												<p>- 서약서 위치 : 개발자센터 > 로그인 > 파트너정보관리 > 파트너 정보</p>
											</li>
										</ul>
									</div>
									<div class="guide_group">
										<h4 class="group_title">1-2. 개인정보 취급 서약서 작성(서약서 작성)</h4>
										<ul class="guide_list">
											<li>
												<span class="num">1</span>
												<img src="/wapu/assets/img/content/img_guide_02_02.jpg" alt="">
												<p>
													1) API 이용에 관란 확인서 항목 별로 작성<br>
												</p>
												<p>
													2) 첨부해 주신 서약서에는 귀사의 사이트에서 공시하고 있는 약관이나 개인정보처리 방침에 명시된 [개인정보보호채임자] 혹은 [대표자]의 서명이 기재되어야 합니다.
												</p>
											</li>
										</ul>
									</div>
									<div class="guide_group">
										<h4 class="group_title">2. 서약서 CAFE24 운영팀 송부</h4>
										<ul class="guide_list">
											<li>
												<span class="num">1</span>
												<img src="/wapu/assets/img/content/img_guide_02_02.jpg" alt="">
												<p>
													1) 개인정보 취급 서약서를 스캔하여 아래의 메일 주소로 송부<br>
													- 메일주소 : <a href="mailto:app_help@cafe24corp.com">app_help@cafe24corp.com</a>
												</p>
											</li>
										</ul>
									</div>
									<div class="guide_group">
										<h4 class="group_title">3. 개인정보 권한 부여 확인</h4>
										<ul class="guide_list">
											<li><button type="button" class="btn_th btn_modal" data-id="cafe24">정보 확인 가이드</button>
												<span class="num">1</span>
												<img src="/wapu/assets/img/content/img_guide_02_02.jpg" alt="">
												<p>
													1) 아래의 메일 주소로 위와 같이 권한 추가 메일 회신이 오면 개인정보 서약서 작성 완료<br>
													- 메일주소 : <a href="mailto:app_help@cafe24corp.com">app_help@cafe24corp.com</a>
												</p>
											</li>
										</ul>
									</div>
								</div>
								<div class="tab_panel">
									<h3 class="guide_title blind">3. 인증정보 입력</h3>
									<div class="guide_group">
										<h4 class="group_title">1. 홈페이지 API 인증 정보 입력</h4>
										<ul class="guide_list">
											<li>
												<span class="num">1</span>
												<img src="/wapu/assets/img/content/img_guide_03_01.jpg" alt="">
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="modal_content modal_prepare" data-id="godomall">
					<div class="modal_head">
						<button type="button" class="btn_close"><span class="blind">닫기</span></button>
					</div>
					<div class="modal_body">
						<div class="prepare_text">고도몰 정보 확인 가이드는<br>준비중 입니다.</div>
					</div>
				</div>
			</div>


		</div>
	</div>
</template>

<script>
import StringUtils from "../../commons/utils/string.utils";

// const axios = require('axios').default;
export default {
	name: "applyData",
	data() {
		return {
			serviceApplyVO: new this.ServiceApplyVO(this.getSessionProc()),
			userInfo: {},
			pgConfig: {},
			tid: "",

			resultCode : this.$route.query.resultCode,
			resultMsg : this.$route.query.resultMsg,

			haveBillInfo: false
		}
	},
	created() {
		//개발
		// this.$loadScript("https://stgstdpay.inicis.com/stdjs/INIStdPay.js")
		// 운영
		this.$loadScript("https://stdpay.inicis.com/stdjs/INIStdPay.js")
	},
	async mounted() {
		const self = this;

		if(this.resultCode!=undefined && this.resultMsg !=undefined) {
			if(this.resultCode == "0000") {
				self.swalUtils.gritter(this.resultMsg, "", "success", 1500)
						.then(() => {
							self.$router.push({ path: '/service/check' })
						})
			} else {
				self.swalUtils.gritter(this.resultMsg, "", "warning", 1500)
			}
		}

		await this.getServiceBillInfo()
		await this.regDate()
		await this.regOrderNumber()
		await this.getPgConfig()

	},
	methods: {

		nextPage() {
			if (!this.isLogin()) {
				return self.swalUtils.gritter("로그인이 필요합니다", "", "error", 800)
			}

			if (this.serviceApplyVO.reqDate=="" && this.serviceApplyVO.reqChannel !='consul') {
				return self.swalUtils.gritter("필수 입력란을 확인해주세요", "", "warning", 800)
			}

			let sService =this.getSessionProc();
			sService.serviceApplyVO = this.serviceApplyVO;
			this.setSessionProc(sService)

			this.$router.push('/service/check')
		},

		getServiceBillInfo () {
			const self = this;

			if( this.isLogin() ) {

				let emailId = this.getSessionProc().emailId;

				this.axiosCaller.get(this, this.APIs.SERVICE_APPLY + "/getServiceBillInfo", { 'emailId' : emailId }, (res) => {
					let result = res.data;
					if (result.status === "ok") {

						if( result.haveBillInfo ) {
							self.haveBillInfo = true;
						}

					} else {
						this.swalUtils.gritter("Error", result.msg, "error")
								.then(() => {
									location.href = document.referrer
								})
					}
				});
			}

		},
		getPgConfig () {
			const self = this;
			this.axiosCaller.get(this, this.APIs.PG_SERVICE + "/getPgConfig", {'orderNum':self.serviceApplyVO.orderNumber}, (res) => {
				let result = res.data;
				if (result.status === "ok") {
					self.pgConfig = result.pgConfig;

					//console.log(':::::::::::pgConfig',self.pgConfig)

				} else {
					this.swalUtils.gritter("Error", result.msg, "error")
							.then(() => {
								location.href = document.referrer
							})
				}
				self.$eventBus.$emit("initApp");
			});
		},
		paybtn () {
			if (!this.isLogin()) {
				return self.swalUtils.gritter("로그인이 필요합니다", "", "error", 800)
			}

			if (!StringUtils.isNumber(this.userInfo.phoneNumber)) {
				return self.swalUtils.gritter("전화번호는 숫자만 입력해주세요", "", "error", 800)
			}

			if (this.serviceApplyVO.reqDate=="" || this.userInfo.companyName == null || this.userInfo.phoneNumber == null) {
				return self.swalUtils.gritter("필수 입력란을 확인해주세요", "", "warning", 800)
			}

			let sService =this.getSessionProc();
			sService.serviceApplyVO = this.serviceApplyVO;
			sService.userInfo = this.userInfo;
			this.setSessionProc(sService)

			window.INIStdPay.pay('SendPayForm_id');
		},

		testCancel () {
			this.axiosCaller.post(this, this.APIs.PG_SERVICE + "/testCancel", {'tid':this.tid}, (res) => {
				let result = res.data;
				if (result.status === "ok") {
					console.log("ok")
				} else {
					this.swalUtils.gritter("Error", result.msg, "error")
							.then(() => {
								location.href = document.referrer
							})
				}
			});
		},

		goBack () {
			this.$router.push({path: '/service/info'})
		},

		pad(number, length) {
			var str = '' + number;
			while (str.length < length) {
				str = '0' + str;
			}
			return str;
		},

		regDate() {
			// 날짜 시분초
			let d = new Date();
			let yyyy = d.getFullYear().toString();
			let MM = this.pad(d.getMonth() + 1,2);
			let dd = this.pad(d.getDate(), 2);
			let hh = this.pad(d.getHours(), 2);
			let mm = this.pad(d.getMinutes(), 2)
			let ss = this.pad(d.getSeconds(), 2)

			this.orderDate = yyyy +  MM + dd +  hh + mm + ss;
		},
		regOrderNumber() {
			let channel = '';
			// 채널 첫글자
			switch (this.serviceApplyVO.reqChannel) {
				case "cafe":
					channel = "A";
					break;
				case "godo":
					channel = "B";
					break;
				case "erp":
					channel = "C";
					break;
				case "data":
					channel = "D";
					break;
				case "etc":
					channel = "E";
					break;
			}
			this.serviceApplyVO.orderNumber = 'W' + channel + this.orderDate;
		},
	}
}
</script>

<style scoped>

</style>